<template>
  <!-- IF PASSWORD RESET TOKEN IS VALID -->
  <div class="divide-y divide-gray-200 lg:col-span-9">
    <div class="py-6 px-4 sm:p-6 lg:pb-8">
      <div>
        <h2 class="text-lg leading-6 font-medium text-gray-900">Reset Password</h2>
        <p class="mt-1 text-sm text-gray-500">
          {{ message }}
        </p>
      </div>
      <!-- Gallery -->
      <LoadingSpinner v-if="isLoading" class="justify-center items-center"/>
      <section v-if="!isLoading" class="mt-8 pb-16" aria-labelledby="gallery-heading">
        <div v-if="isValidToken" class="flex flex-col">
          <div class="md:w-1/2">
            <div>
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div class="mt-1">
                <input
                  @input="updateValues('password1', $event.target.value)"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                  "
                />
              </div>
            </div>

            <div class="mt-4">
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >
                Confirm Password
              </label>
              <div class="mt-1">
                <input
                  @input="updateValues('password2', $event.target.value)"
                  id="password2"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  class="
                    appearance-none
                    block
                    w-full
                    border border-gray-300
                    rounded-md
                    shadow-sm
                    placeholder-gray-400
                    focus:outline-none
                    focus:ring-indigo-500
                    focus:border-indigo-500
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="mt-4 py-4 px-4 flex justify-end sm:px-6">
      <button
        type="submit"
        class="
          ml-5
          border border-transparent
          rounded-md
          shadow-sm
          py-2
          px-4
          inline-flex
          justify-center
          text-sm
          font-medium
          text-white
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-blue-400
        "
        :disabled="!doMatch"
        v-on:click="submit()"
        v-bind:class="[
          doMatch
            ? 'bg-indigo-600 hover:bg-blue-700'
            : 'bg-indigo-400 cursor-not-allowed',
        ]"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
import LoadingSpinner from "../components/LoadingSpinner.vue";
import { NopService } from '../services/nopService';
import { useReCaptcha } from 'vue-recaptcha-v3'

const passwordValues = {
    password1: "",
    password2: "",
}

export default {
  components: {
    LoadingSpinner
  },
  setup() {
      const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()

      const recaptcha = async () => {
        // (optional) Wait until recaptcha has been loaded.
        await recaptchaLoaded()
        // Execute reCAPTCHA with action "login".
        const gtoken = await executeRecaptcha('login')
        // Do stuff with the received token.
        let resp = await NopService.validateRecaptcha(gtoken)
        return (resp.data.status == 1)
      }
      return {
        recaptcha,
          passwordValues
      }
  },
  data() {
      return {
          doMatch: false,
          isValidToken: false, //SET TO FALSE
          isLoading: true,
          token: this.$route.query.token,
          guid: this.$route.query.guid,
          message: "Reset your password",
          isValidRecaptcha: false,
      }
  },
  async created() {
      this.isValidToken = await this.validateTokens();
  },
  methods: {
      updateValues(field, value) {
          this.passwordValues[field] = value;
          if (this.passwordValues.password1 && this.passwordValues.password2 && this.passwordValues.password1 === this.passwordValues.password2) {
            this.doMatch = true;
          }
          else {
            this.doMatch = false;
          }
      },
      async validateTokens() {
        let resp = await NopService.confirmPasswordTokens(this.token, this.guid);
        this.isLoading = false;
        if (resp.Result === "Valid") {
          return true
        }
        this.message = resp.Result;
        return false
      },
      async submit() {
        this.isValidRecaptcha = await this.recaptcha();
        if (this.isValidRecaptcha && this.doMatch) {
          let resp = await NopService.submitNewPassword(this.token, this.guid, this.passwordValues.password1);
          this.message = resp.Result;
          this.isValidToken = false;
          this.doMatch = false;
          this.passwordValues.password1 = "";
          this.passwordValues.password2 = "";
        }
      }
  },
};
</script>
